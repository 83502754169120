.thesis-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Makes the container take up the full viewport height */
  text-align: center; /* Centers text inside the container */
}

.btn-primary {
  font-size: 1.2rem; /* Optional: Adjust button size */
  padding: 10px 20px; /* Optional: Add padding for better appearance */
}

  .thesis-header {
    font-size: 2.5rem;
    font-weight: 700;
    color: #4ec7ff; /* spartan blue */
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    margin-bottom: 3rem;
    text-align: center;
  }
  
  .thesis-subheader {
    font-size: 1.75rem;
    font-weight: 500;
    color: #f5e238; /* spartan gold */
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .thesis-paragraph {
    font-size: 1.125rem;
    line-height: 1.6;
    color: #495057; /* Grayish */
    margin-bottom: 1.5rem;
    text-align: justify;
    text-align: center;
  }
  
  .img-fluid {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    height: auto;
    border-radius: 0.5rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  }
  