/* Career.css */
.career-container {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  padding-top: 8rem; /* Increased top padding */
  padding-bottom: 8rem; /* Increased bottom padding */

  background-image: url('/public/backgrounds/career-background.jpg'); /* Replace with your image path */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #000000; /* Adjust text color based on background */
  padding: 20px;



}


.card ul {
  padding: 0;
  margin: 0;
  list-style: none; /* Remove default browser styling */
}

.card ul li {
  display: list-item; /* Ensure each list item behaves like a normal list item */
  list-style-type: disc; /* Adds a bullet point */
  margin-left: 1.5rem; /* Add space between bullet point and text */
  margin-bottom: 0.5rem; /* Adds space between list items */
  line-height: 1.8; /* Ensures good spacing between lines */
}

.card ul {
  display: block; /* Prevents any flex or grid styling from affecting the list */
}

.card {
  display: block; /* Ensure the card behaves as a block container */
  background-color: rgba(255, 255, 255, 0.95); /* Slightly transparent white */
  color: #333; /* Dark text for readability */
  padding: 1vh; /* Add padding for spacing */
  box-sizing: border-box; /* Include padding in the width calculation */
  margin: 0 0 2rem 0; /* Add space between cards */
  border-radius: 0.5rem; /* Rounded corners */
  box-shadow: 0 20px 35px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}


.card-title, .card-duration {
  color: #333; /* Dark color for the text */
  font-weight: 600; /* Make it bold */
}

ul li::before {
  color: #333; /* Dark color for bullet point icons */
}

@media (max-width: 768px) {


  .card {
    width: 100%; /* Full width on smaller screens */
    margin-bottom: 20px; /* Add some space between cards */
  }
}
