
/* AboutMe.css */

/* Essential Swiper styles */
.carousel-container {
  position: relative;
  min-height: 500px; /* Minimum starting height */
  overflow: hidden;
  box-shadow: 0 80px 20px rgba(0, 0, 0, 0.15);
  transition: height 1.5s ease-in-out;
  margin: 0 auto;
}

/* Hide inactive slides */
.swiper-slide {
  visibility: hidden;
  transition: opacity 0.3s ease;
  height: auto;
}

/* Show only active slide */
.swiper-slide-active {
  opacity: 1;
  visibility: visible;
}

/* Navigation buttons */
.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 60px;
  height: 60px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 10;
  cursor: pointer;
  transition: background-image 0.3s ease;
}

.swiper-button-next {
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8);
}

.swiper-button-prev {
  left: 10px;
  background-color: rgba(255, 255, 255, 0.8);
}

/* Navigation icons hover effects */
.swiper-button-next i,
.swiper-button-prev i {
  opacity: 0;
  transform: translateY(10px);
  position: absolute;
  left: 50%;
  top: -30px;
  transform: translate(-50%, 10px);
  font-size: 32px;
  color: #00ff0d;
  transition: all 0.3s ease-in-out;
}

.swiper-button-next:hover i,
.swiper-button-prev:hover i {
  opacity: 1;
  transform: translate(-50%, -25px);
}

/* Add these to your existing CSS */
@media (max-width: 1024px) {
  .carousel-container {
    min-height: 400px;
    max-width: 100%;
    margin: 0 auto;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}





