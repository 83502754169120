.App {
  text-align: center;
  box-sizing: border-box;
}

header{
  background-color: rgb(56, 56, 56);
}

/* Optionally set the background for the root element if it differs from the body */
#root {
  min-height: 100vh; /* Ensure it covers the full viewport height */
  background-color: #ffffff;
  background-size: cover; /* For background images */
  background-repeat: no-repeat;
  background-position: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex; /* Makes the list items display in a row */
  flex-wrap: nowrap; /* Prevents items from wrapping onto the next line */
  overflow-x: auto; /* Allows horizontal scrolling if content overflows */
}

ul li {
  white-space: nowrap; /* Prevents the text from breaking onto a new line */
  padding: 0 10px; /* Adds spacing between items */
}

nav {
  width: 100%;
}

a {
  cursor: pointer;
}

.menu {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: normal;
  height: 6vh;
}

.menu li {
  display: inline-block;
  width: 20vh;
  transition: all 0.3s ease-in-out;
}

.menu li:hover .submenu {
  height: 5vh;
}

.menu > li:hover > a {
  color: #3e95ff;
}

.menu > li:hover > a::before {
  visibility: visible;
  scale: 1 1;
}

.submenu {
  overflow: hidden;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  background-color: #3e95ff;
  height: 0;
  line-height: 40px;
  box-sizing: border-box;
  transition: height 0.3s ease-in-out;
}
.menu li .submenu a {
  color: #ffffff;
  opacity: 0;
  font-size: 16px;
  transition: opacity 0.25s;
}

.menu > li:hover .submenu a {
  opacity: 1;
}

.menu > li .submenu a:hover {
  background: rgb(0 0 0 / 20%);
}

.menu > li a {
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2vh 2vh 2vh;
  text-decoration: none;
  position: relative;
  height: 100%;
}

.menu > li > a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 3px;
  left: 0px;
  background-color: #3e95ff;
  transition: all 0.2s ease-in-out;
  scale: 0 0;
  visibility: hidden;
}



.App-header {
  
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
