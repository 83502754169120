/* BillSplit.css */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css');

/* General Styling */
.bill-split-container {
    font-family: 'Arial', sans-serif;
    background-color: #f8f9fa;
    color: #333;
    padding: 2rem;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    overflow-y: auto; /* Allow scrolling for the entire container */
}

.bill-split-container h1, .bill-split-container h2 {
    text-align: center;
    margin-bottom: 1rem;
}

.bill-split-container h1 {
    font-size: 2.5rem;
    color: #007bff;
}

.bill-split-container button {
    cursor: pointer;
}

/* Section Styling */
.bill-split-section, .bill-split-user-section {
    background-color: #ffffff;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    max-height: 40vh; /* Limit height for scrolling */
    overflow-y: auto;
}

/* Buttons */
.bill-split-container button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    transition: all 0.3s ease;
    margin: 0.3rem;
}

.bill-split-container button:hover {
    background-color: #0056b3;
}

/* Input Fields */
.bill-split-container input[type="text"],
.bill-split-container input[type="number"] {
    display: block;
    width: 100%;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 5px;
}

/* Lists */
.bill-split-container ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap; /* Wrap users/items */
    gap: 0.5rem;
    max-height: 40vh; /* Limit height for scrolling */
    overflow-y: auto; /* Allow scrolling */
}

.bill-split-container li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #f1f1f1;
    width: calc(50% - 1rem); /* Adjust width for wrapping */
    box-sizing: border-box;
}

.bill-split-container li span {
    cursor: pointer;
    transition: color 0.3s;
}

.bill-split-container li span:hover {
    color: #007bff;
}

/* Selected User Highlight */
.bill-split-container .selected {
    font-weight: bold;
    color: #007bff;
}

/* Delete Confirmation */
.bill-split-container .confirm {
    background-color: #ffc107;
    color: #333;
    padding: 0.5rem;
    border-radius: 5px;
    margin-top: 0.5rem;
    text-align: center;
}

/* Finish Button */
.bill-split-container .finish-button {
    display: block;
    margin: 1rem auto;
    font-size: 1.2rem;
    background-color: #28a745;
    padding: 0.75rem 1.5rem;
}

.bill-split-container .finish-button:hover {
    background-color: #218838;
}

/* Responsive Design */
@media (max-width: 768px) {
    .bill-split-container {
        padding: 1rem;
    }

    .bill-split-container h1 {
        font-size: 2rem;
    }

    .bill-split-container li {
        width: 100%; /* Full width for smaller screens */
    }
}
