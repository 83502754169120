.spotify-weather-app {
    text-align: center;
    padding: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    margin: 0;
    transition: background-color 1s ease-in-out, background-image 1s ease-in-out;
}

.spotify-weather-app.fade-in {
    animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.spotify-weather-happy {
    background-color: #FBAB7E;
    background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);
}

.spotify-weather-sad {
    background-color: #0093E9;
    background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
}

.spotify-weather-chill {
    background-color: #a1bcce;
    background-image: linear-gradient(45deg, #7FB3D5, #3498DB);
}

.spotify-weather-calm {
    background-color: #00ff66;
    background-image: linear-gradient(45deg, #A9DFBF, #52BE80);
}

.spotify-weather-mixed {
    background-color: #FFFFFF;
    background-image: linear-gradient(180deg, #FFFFFF 0%, #6284FF 50%, #FF0000 100%);
}



.spotify-weather-app {
    text-align: center;
    padding: 20px;
}

.spotify-weather-status {
    color: blue;
}

.spotify-weather-error {
    color: red;
}

.spotify-weather-button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.spotify-weather-button:hover {
    background-color: #0056b3;
}

.spotify-weather-playlist {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}


.spotify-weather-playlist img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}
