/* Contact.css */
.contact-container {
  width: 100%;
  min-height: 100vh; /* Ensures it spans the full height of the viewport */
  padding: 20px;
  background-color: #f9f9f9; /* Background color */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; /* Centers content vertically */
  box-sizing: border-box;
}
.contact-row {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    width: 100%;
    margin: auto;
}

.contact-col-left, .contact-col-right {
    flex: 1 1 50%;
    padding: 20px;
}

.contact-image {
    max-width: 100%;
    border-radius: 10px;
}

.contact-form {
    width: 100%;
}

.form-group {
    margin-bottom: 15px;
}

.contact-recaptcha {
    margin: 15px 0;
}

.contact-submit-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.contact-submit-button:hover {
    background-color: #0056b3;
}

.contact-details {
    margin-top: 20px;
    font-size: 16px;
}

.contact-social-icons {
    display: flex;
    gap: 15px;
    margin-top: 15px;
}

.contact-social-icons a {
    color: #333;
    transition: color 0.3s;
}

.contact-social-icons a:hover {
    color: #007bff;
}
